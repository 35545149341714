@import "../../assets/scss/index.scss";

.sidebar
{
    // background-color: $green2;
    // background-color: rgb(37, 37, 88);
    background-color: white;
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 250px;
    display: flex;
    z-index: 101;
    flex-direction: column;  
    transform: translateX(-100%);  
    transition: transform 0.8s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    // border-right: 1px dotted $green;
    box-shadow: 1px 0px 5px 0px #888888;

    &.open
    { 
        transition: transform 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: translateX(0);
    }
    

    @include mq("fluid-out")
    {  
       
        // transform: translateX(0)  !important;
    }

    .logo
    {
        display: inline-block;
        margin: 20px 10px 30px;
        align-self: center;
    }

    .navItem
    {
        padding:10px 20px;
        display: block;
        width: 100%;
        padding-bottom: 24px;

        .logout
        {
            background-color: transparent;
            border: none;
        }

        a,.logout
        {
            width: 100%;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            // color: #fff;
            color: $green2;
            font-size: 15px;
        }

        .navIcon
        {
            // color: $black;
            color: $green;
            display: inline-block;
            margin-right: 20px;
        }
    }

    .subNav
    {
        margin-left: 10%;
        border-left: 1px solid $green;
    }

    .navBtn
    {
        text-align: start;
        background-color: transparent;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        // color: #fff;
        color: $green2;
        border: none;
        display: block;
        width: 100%;
        font-size: 15px;

        .pre
        {
            display: flex;
        }

        .navCaret
        {
           margin-left: 20px;
        }
        
    }

    .navCaret
    {
        transform: rotate(0deg);
        transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        &.open
        {        
            transform: rotate(180deg);

        }
        
    }

    .collapse
    {
        max-height: 0;
        transition: max-height 0.1s ease-out;
        overflow:hidden;

        &.open
        {
            max-height: 600px;
            opacity: 1;
            transition: max-height .2s ease-in;
        }
    }

    
}


.toggler
{
    position: fixed;
    top: 10px;
    animation: close 0.5s;
    left: 13px;
    z-index: 102;
    // background: $green2;
    background: transparent;
    font-size: 25px;
    padding: 5px;    
    display: flex;
    border-radius: 50%;
    @include mq("fluid-out")
    {
        // display: none;
    }

    svg
    {
        transform: rotateY(0deg);
        background-color: transparent;
        // transform: translateX(200px);
        transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    }

    &.open
    {
        animation: open 0.5s;
        left: 213px;
        svg
        {
            transform: rotateY(180deg);
            transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
    }

    @keyframes open {
        from{left: 13px;}
        to{left: 213px;}
    }

    @keyframes close {
        from{left: 213px;}
        to{left: 13px;}
    }
}

/* width */
::-webkit-scrollbar {
width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: whitesmoke;
}

/* Handle */
::-webkit-scrollbar-thumb {
// background: #888;
background: $green2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
// background: #555;
background: $green;
}