@import "../../assets/scss/index.scss";

.flex
{
   @include flex-btw;

}

.title
{
    font-family: Raleway;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    text-transform: capitalize;
}
