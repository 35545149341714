@import "../../assets/scss/index.scss";

.currency-card{
  display: flex;
  margin: 10px auto;
  width: 400px;
  // border-radius: 10px;

  .currency-image{
      width: 40px;
      margin-right: 20px;
      align-self: center;
  }

  .currency-name{
      flex: 30%;
      font-weight: 600;
      font-size: 16px;
      align-self: center;
  }

  .currency-amount{
      flex: 20%;
      font-size: 18px;
      align-self: center;
      font-family: monospace;
  }
}

.filter-wrapper{
  display: flex;
  background-color: rgb(192, 189, 186, 0.2);
  padding: 20px 15px;
  margin: 0px 0px 10px 0px;
  margin-bottom: 0px;
  flex-wrap: wrap;
  border-radius: 5px;
}

.dark{
  background-color: rgb(169, 173, 172);
  color: $black;
}

.filter{
  flex: 25%;
  margin: 10px 15px 10px 0px;
  display: flex;
  justify-content: center;align-items: center;

  label{
    font-size: 16px;
    margin-right: 8px;
  }
}

.select{
  padding: 5px 10px;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
}

.date-picker-wrapper{
  display: flex;
  padding: 10px;
}

.date-picker{
  margin-top: 0px;
  height: 50px;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  label{
    font-size: 15px;
    margin-right: 12px;
    font-weight: 500;
  }

  input{
    padding: 5px;
    border-radius: 7px;
    font-size: 15px;
    // background-color: rgb(192, 189, 186, 0.2);
  }
}