@import "../../assets/scss/index.scss";

.error
{
    p
    {
        font-size: 20px;
        color: $red
    }

    button
    {
        background-color:$green;
        color: #fff;
        border-radius: 16px;
        padding: 15px;
        font-weight: 500;
        font-size: 16px;
        display: inline-block;
        margin: 10px 0;
        

    }
}
