@import "../../assets/scss/index.scss";
.page
{
    margin: 30px;
    padding: 50px;
    background-color: white;
    border-radius: 5px;
    box-shadow: -3px 5px 2px 1px rgba(77, 48, 48, 0.1);
    position: relative;
    overflow-x: hidden;
    min-height: 700px;

    @include mq(phone-wide, max){
        padding: 20px;
    }

    @include mq(desktop-med, max){
        padding: 30px;
    }
}

.dark{
    background-color: $background;
    color: $green2;
}
