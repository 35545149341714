@import "../../assets/scss/index.scss";

.grid
{
    margin: 15px 0;

    @include mq("tablet")
    {  display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
        margin: 15px 0;
    }

    .item
    {
        background: #fff;
        border-radius: 8px;
        padding: 8px 16px;
        min-height: 50px;
        margin: 10px;

        .loader
        {
            margin: 30px auto;
            width: 0.2em;
            height: 0.2em;
            font-size: 10px;
        }
       

        .title
        {
            margin-bottom: 10px;
        }

        .bal
        {
            text-align: end;   
            color:$green;     
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 1px;
        }

    }
}
