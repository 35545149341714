@import "../../../assets/scss/index.scss";

.home
{
    overflow: unset
}

.btnDropDowns
{
    @include flex-btw;
    flex-wrap: wrap;
    
    min-width: 60%;

    @include mq("fluid-out")
    {
        min-width: 35%;
    }

    button
    {
        padding:8px 15px;
        background-color: $green;
        color: #fff; 
        font-family: Raleway;
        font-size: 15px;
        background-image: url("../../../assets/images/caret-white.svg") ;
        background-position: 92% center;
        background-size: 12px;
        background-repeat:no-repeat;
        border: none;
        padding-right: 20px;
        border-radius: 8px;
        min-width: 80px;
        text-align: left;
        position: relative;
        transform: none !important;


        &.noCaret
        {
            background-image: none;
            padding-right: 15px;
        }

        .list
        {
            list-style-type: none;
            background:#fff;
            position: absolute;
            z-index: 6;
            left: 0;
            top: 100%;
            color: $green;
            padding: 10px 0;
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            text-align: center;

            li
            {
                padding: 5px;
                transition: all;
                &:hover
                {
                    background: $grey;
                    color: #fff;
                }
               
            }
        }
    }
}

.card
{
    background: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    min-height: 50px;
    margin: 10px;
}

.grid
{
    @include mq("tablet")
    {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;

    }
}

.topRow
{
    margin: 10px auto;
  

   .info
   {

        .miniHeader
        {
            @include flex-btw;

            & svg
            {
                fill:$green
            }

            .rot
            {
                img
                {
                    transform: rotate(180deg);
                }
            }

            .drpdwnBtn
            {
                color: $green;
                font-family: Raleway;
                font-size: 14px;
                background-color: transparent;
                background-image: url("../../../assets/images/caret.svg") ;
                background-position: right center;
                background-size: 12px;
                padding-right: 20px;
                background-repeat:no-repeat;
                border: none;
                min-width: 82px;
                position: relative;
                transform: none !important;
                

                span
                {
                    background: rgba(51, 213, 186, 0.1);
                    border-radius: 4px;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    padding:5px;
                }
            }
        }


        .value
        {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: $green2;
            margin: 10px 0;
        }

        .footer
        {
            font-size: 13px;
        }
   }
}

.bottomRow
{
    
    &.grid
    {
        gap: 20px;
        grid-template-columns: repeat(1,1fr);
    }

    .chartHeader
    {
        font-family: Raleway;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color:$black;
        margin: 10px 0;
    }

    .chart
    {
       background: rgba(48, 48, 48, 0.005);
       border: 1px solid rgba(48, 48, 48, 0.5);
       border-radius: 8px;
       margin:10px 0;
       height: 200px;
       padding: 10px 5px 0;
    }

    

    .pie
    {
      
        overflow: scroll;
        display: grid;
        grid-template-columns: 30% 70%;
        min-height: fit-content;
        
        .keys
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 10px;
        }
    }

    
}

.header
{
    font-family: Raleway;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color:$black;
    margin: 10px 0;
}

.chartCover
{
    background: rgba(48, 48, 48, 0.005);
    border: 1px solid rgba(48, 48, 48, 0.5);
    border-radius: 8px;
    margin:10px 0;
    height: 200px;
    padding: 35px 5px 0px;
}