@import "../../assets/scss/index.scss";

.search{
    text-align: right;
    margin-bottom: 25px;
    
    @include searchBox;
    .cancel{
        position: absolute;
        right: 10px;
        color: $red;
        font-size: 14px;
        cursor: pointer;
    }
}

.select{
    position: relative;
    background-color: whitesmoke;
    margin: 0;
    // width: 120px;
    height: 25px;
    border-radius: 3px;
    border: none;
    padding-left: 5px;
    font-family: "Raleway", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
    color: #303030;

    @include mq(phone-small,max){
        width: 60px;
    }
}

.dark{
    select{
        background-color: gray;
        color: black;
        border: 1px solid rgb(128, 128, 128, 0.4);
        margin-right: 8px;
    }

    input{
        background-color: transparent;
        color: whitesmoke;
        border: 1px solid rgb(128, 128, 128, 0.4);
        &::placeholder{
            color: rgb(114, 112, 112);
        }
    }
}