@import "./assets/scss/index.scss";

*
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html
{
  width: 100%;
}

body 
{
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6;
}

code 
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button
{
  cursor: pointer;
  border: none;
  outline: none;
  display: inline-block;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

a
{
  text-decoration: none;
  display: inline-block;
}

button:disabled
{
  opacity: 0.5;
}

a:hover,
button:not(:disabled):hover
{
  transform: scale(1.04);
}

input
{
  outline: none;
}

::-webkit-scrollbar 
{
        width: 10px;
}
/* Track */
::-webkit-scrollbar-track 
{
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb 
{
    background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover 
{
    background: #555;
}
