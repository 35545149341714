@import '../../assets/scss/index.scss';

.alertModal {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    max-width: 100vw;
    height: 100%;
    min-height: 100%;
    // background-color: rgba(255, 255, 255, 0.61);
    background-color: rgba(5, 5, 5, 0.45);
    z-index: 1000;
    overflow-x: hidden !important;
    // @include flex-center(column);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.hide {
      display: none !important;
        background: #fff;
    }
  }

  .wrapper
  {
    position: relative;

    .cancel
    {
       position: absolute;
       right: 10px;
       top: 10px;
       background: none;
       color:black;
       font-size: 20px;
       cursor: pointer;
    }
  }

  .modalContent{
    // color: #fff;
    color: black;
    top: 50%;
    // background: rgba(48, 48, 48, 0.8);
    background: rgb(252, 250, 250);
    min-width: 325px;
    max-width: 550px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 25px;
    padding: 15px;
    justify-items: center;
    align-items: center;
    font-size: 15px;
  }

  .button{
      @include button(40%,0.3em 1em);
  }

  .modalBody{
    position: absolute;
    top: 20%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
