@import "../../../assets/scss/index.scss";

.login{
    background: $grey2 ;
    
    .formBox{
        z-index: 10;
        width: 90vw;
        margin:auto;
        color:$black;
        padding: 25px 0 45px;

        position: relative;
        min-height: 100vh;
        background: $grey2 url("../../../assets/images/bg.png") no-repeat;
        background-size: unset;
        background-position: top 50px left 20px;
        @include mq("phablet",max)
        {
            background-position: top 40px left 0px;
        }  

        .formWrap{
            position: relative;
            top: 0px;
            background: #fff;
            max-width: 90vw;
            border: 1px solid #33D5BA;
            border-radius: 16px;
            padding: 30px 19.2px;        
            min-height: 322px;     
            overflow: hidden;
            z-index:2;
            margin: 0 auto;
            @include mq("tablet","min")
            {
                max-width: 60vw;
                padding:30px 26px;     
            }  
             
            .error {
                @include error;
            }

            .formRow{
                color: $black;
                position: relative;
                overflow: hidden;
                width: 90%;
                margin: 0 auto;
                margin-top: 20px;
                margin-bottom: 20px;
                @include mq("tablet-small","min")
                {
                    width: 65%;
                }  
                ::placeholder {
                    color: rgba(48, 48, 48, 0.5);
                    font-size: 14px;
                }
                label {
                    font-weight: 600;
                    position: absolute;
                    left: -1px;
                    height: 100%;
                    width: 40px;
                    padding: 15px 0 0 10px;
                    font-size: 18px;
                    cursor: pointer;                    background: #fff;
                    border-radius: 16px;
                }

                input {
                    width: 100%;
                    height: 46px;
                    font-family: "Raleway", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
                    sans-serif;
                    color: #303030;
                    padding: 15.5px 12px;
                    padding-left: 50px;
                    font-size: 16px;
                    border-radius: 16px;
                    border: solid 1px rgba(53, 120, 229, 0.1);
                    background-color: #f9f9f9;
                }
                .toggle{
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    color: $black;
                    font-size: 18px;
                    cursor: pointer;
                  }
                  input:focus {
                        border: 1px solid $green;
                    }
            }
            .remember{
                width: 100%;
                cursor: pointer;
                position: relative;
                left: 17%;
                @include mq('phone-wide',max){
                    left: 7%;
                }
                .checkBox{
                    position: relative;
                    cursor: pointer;
                    width: 18px;
                    height: 18px;
                    outline: none;
                    -webkit-appearance: none;
                    transition: all 0.05s ease-in;

                    &::before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        margin-left: 10px;
                        border: 2px solid $green;
                        transition: all 0.05s ease-in;
                    }
                    &:checked::before{
                        border-top: none;
                        border-left: none;
                        width: 10px;
                        transform: rotate(45deg);
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    h3{
        margin: 30px 0;
        text-align: center;
        font-size: 22px;
    }
}

.forgot-password{
    text-align: center;
    text-decoration: underline;
    color: $green2;
    margin-top: 20px;
    cursor: pointer;
    &:hover{
        color: $green;
        transform: scale(110%)
    }
}


.submit{
    @include button(70%);
    padding: 0.7em 2em;
    margin: 30px auto 10px;
  
    @include mq(phone-wide){
        width:55%;
    }
}

.authTop{
    @include flex-btw;
    background: #fff;
    padding: 0 10px;
    &.scroll{
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2000;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
    }
    img
        {
            margin: 10px 0;
            transform: scale(0.8) translateX(-14%);
        }

        p{
            color: $green;
        }
}

.forgot-password-text{
    text-align: center;
    margin-bottom: 60px;
}

.back-button{
    color: $green;
    font-size: 20px;
    cursor: pointer;
    &:hover{
        transform: scale(125%);
    }
}

.error-text{
    color: red;
    font-size: 15px;
    text-align: center;
}

.form-error-text{
    color: red;
    text-align: right;
    font-size: 12px;
    font-family: serif;
}