@import "../../assets/scss/index.scss";

.grid
{
    //display: grid;
    min-height: 100vh;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
    "header header header header header"
    "main main main main main"
    "main main main main main"
    "main main main main main";

    @include mq("fluid-out")
    {    
        //uncomment this to enforce empty space on the left for sidebar to fill
        display: grid;    
        grid-template-columns: 250px 1fr;
        // grid-template-areas:
        // "aside header header header header header"
        // "aside main main main main main"
        // "aside main main main main main"
        // "aside main main main main main";
    }

    .aside
    {
        grid-area: aside;
    }

    .header
    {
        min-height: 80px;
        grid-area: header;
        position: relative;
    }

    .main
    {
        margin: 20px 0 10px;
        grid-area: main;       
    }

    &.fixed{
        @include mq("fluid-out")
        { 
            grid-template-areas:
            "aside header header header header header"
            "aside main main main main main"
            "aside main main main main main"
            "aside main main main main main";
        }
    }
}

.dark{
    background-color: $page_bg;
}

