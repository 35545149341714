@import "../../assets/scss/index.scss";

.progress
{

    .header
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p
        {
            font-size: 15px;

            &.target
            {
                font-size: 24px;
                color:$green;
            }
        }


    }

    .bar
    {
        background: rgba(228, 228, 228, 0.3);
        border-radius: 16px;
        height: 50px;
        margin: 50px 0 20px;
        .indic
        {
            border-radius: inherit;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $yellow;
            height: 100%;
            p
            {
                color:$black;
                font-family: Raleway;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }

}

.key
{
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 10px;
    width: 100%;
    margin-bottom: 5px;

    .keyColor
    {
        height: 100%;
    }

    p
    {
        text-transform: uppercase;
    }
}

.profit
{

    .header
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .target{
            display: flex;
            font-size: 17px;
            font-family: monospace;
            padding: 10px;
            border-radius: 5px;

            .header{
                margin-right: 10px;
                font-size: 17px;
                font-weight: 600;
                color: $border_color;
            }

            .currencyName{
                margin-right: 5px;
                font-size: 17px;
                font-weight: 600;
                color: $green2;
            }
        }
    }

    .select{
        min-height: 30px;
        // width: 20%;
        padding: 2px 10px;
        border-radius: 5px;
        font-size: 13px;
        color: $black;
        background-color: $grey2;
        border: 1px solid $border_color;
    }

}

.pieChart{
    display: flex;
    flex-wrap: nowrap;
    padding: 20px;
    height: 100%;

    .keys{
        flex: 60%;
        align-self: center;
    }

    .pie{
        flex: 40%;
    }
}
