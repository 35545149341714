@mixin mq($width, $type: min) 
{
    @if map_has_key($breakpoints, $width) 
    {
        $width: map_get($breakpoints, $width);
        @media only screen and (#{$type}-width: $width) 
        {
            @content;
        }
    }
}

@mixin mqRange($minWidth, $maxWidth) 
{

    @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) 
    {
        @content;
    }
    
}

@mixin flex-center($direction: row) 
{
  display: flex;
  flex-flow: $direction;
  justify-content: center;
  align-items: center;
}
@mixin flex-btw($direction: row, $wrap: nowrap) 
{
  display: flex;
  flex-flow: $direction;
  justify-content: space-between;
  align-items: center;
  flex-wrap: $wrap;
}
@mixin flex-even($direction: row, $wrap: nowrap) 
{
  display: flex;
  flex-flow: $direction;
  justify-content: space-around;
  align-items: center;
  flex-wrap: $wrap;
}

@mixin error {
  color: $red;
  padding: 10px 0 0;
  font-weight: bold;
  font-size: 12px;
}

@mixin button($width,$pad:0.7em 2em,$fontSize:15px) {
  width:$width;
  display: block;
  background:$green2;
  border-radius: 5px;
  color:#fff;
  font-size: $fontSize;
  font-weight: bold;
  padding: $pad;
  max-height: 48px; 
  text-align: center;

  &:hover{
    filter: brightness(70%);
  }
}

@mixin searchBox($min_width:290px,$small_width:260px) {
  position: relative;
  ::placeholder {
    color: rgba(48, 48, 48, 0.5);
    font-size: 14px;
    font-style: italic;
    }
    input{
      min-width: $min_width;
      height: 30px;
      padding: 10px;
      border-radius: 8px;
      border: none;
      padding-left: 20px;
      font-family: "Raleway", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
      color: #303030;

      @include mq(phone-small,max){
          min-width: $small_width;
          width: $small_width;
      }
  }
  svg{
      position: absolute;
      top: 5px;
      right: 25px;
      color: $green;
      font-size: 20px;
      cursor: pointer;
  }
}

@mixin checkbox($bd-radius: 50%,$width:40px,$height:40px) {
  position: relative;
  appearance: none;
  width: $width;
  height: $height;
  color: $green;
  border: 1px solid $green;
  border-radius: $bd-radius;
  cursor: pointer;
  background: $form-bg;
  transition: all 0.1s ease-in;
  &:checked::before{
      content: '✓';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 40px;
      font-size: 25px;
      border-radius: $bd-radius;
      z-index: 9;
  }
  &:checked::after{
      content: '';
      background: $green;
      display: block;
      position: relative;
      border-radius: $bd-radius;
      z-index: 100;
  }
}