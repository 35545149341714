@import "../../assets/scss/index.scss";

@mixin flex-wrap
{
    flex-wrap: wrap;
    @include mq("tablet")
    {
        flex-wrap: nowrap;
    }
}

.wrapper
{
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    margin: 10px;

    .toolbar
    {
        @include flex-btw;
        @include flex-wrap;

        .datePickers
        {
            @include flex-btw;
            @include flex-wrap;


           .dropDown 
            {
                & > div
                {
                    padding: 5px;
                    border-radius: 10px;
                }

                //fix clock overflow issue
                & > span:nth-child(2)
                {
                    inset : 40px 0 0 0 !important;
                    height: 200px !important;
                }
            }

            .go
             {
                 border-radius: 10px;
                 font-weight: bold;
                 background-color: $green2;
                 color: #fff;
                 padding: 10px 20px;
                 transform: none !important;
             }

        }

        .modePickers
        {
            margin-bottom: 20px;

            @include mq("tanlet")
            {
                margin-bottom: 0;
            }

            button
            {
                position: relative;
                padding: 10px;
                transition: all 0.2s ease-in-out;
                z-index: 1;

                &.min
                {
                    background-color: $blue;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                
                &.hour
                {
                    background-color: $yellow;

                }

                
                &.day
                {
                    background-color: $lighter-green;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                }
                    

                &.active
                {
                    background-color: $green;
                    color: #fff;
                    transform: scale(1.05);
                    z-index: 4;
                    box-shadow: 2px 0px 6px 0px $grey;

                }

                
            }

        }
    }

    .chart
    {
        min-height: 250px;
        margin: 10px auto;
        position: relative;

      .chartDiv
      {
          width: 100%;
          height: 300px;
      }

        .overlay
        {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: $overlay;
            display: flex;
        }
    }

    text tspan
    {
        font-size: 10px !important;
    }

    .noData
    {
        font-size: 18px;
        text-align: center;
        margin: 40px 5px;
    }

    .error
    {
        color:$red;
        font-size: 12px;
        font-style: italic;
        margin: 5px;

    }

}
