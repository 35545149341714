@import "../../assets/scss/index.scss";

.wrapper
{
    overflow-x: auto;
    width: 100%;
    max-width: 94vw;
}

.table
{
    border-spacing: 0px 4px; 
    border: 1px solid $green2;
    border-radius: 10px;
    width: 100%;
    padding: 0;

    tr
    {
        font-family: Raleway,'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        // background: rgba(255, 255, 255, 0.911);    
        background-color: transparent;
        color: $green2;
        text-align: center;
        padding: 0;
    }

    .tableHead
    {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 11px;
        color: $green2;

        @include mq(phone-wide, max) {
            font-size: 8px;
        }

        td
        {
            padding: 10px 0;
        }
    }

    .tableRow
    {
        td
        {
            font-size: 11px;
            font-weight: 400;
            padding: 10px 10px;
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include mq(phone-wide, max) {
                font-size: 9px;
                padding: 7px 7px;
            }
            // border-right: 1px solid rgb(0, 0, 0, 0.2);
            border: 1px solid rgb(0, 0, 0, 0.1);
        }

        &.cursor
        {
            cursor: pointer;
            &:hover
            {
                background: $lighter-green;
                color: #000000;
            }
        }

        
    }
}

.tableExts
{
    display: flex;
    background-color: #316879;
    background-color: black;
    border: 1px solid rgb(128, 128, 128, 0.6);
    margin: 10px auto 60px;
    padding: 10px 35px;
    border-radius: 10px;
    @include mq(phone-wide, max) {
        margin: 10px auto 10px;
        font-size: 10px;
    }
    align-items: center;
    justify-content: space-between;
    align-items: center;
    color: white;

    .btns
    {
        align-items: center;
        display: flex;
        .control
        {
            background: #316879;
            color: white;
            font-size: 20px;
            border: none;
            padding: 5px;
            display: flex;
            align-items: center;
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
            @include mq(phone-wide, max) {
                font-size: 15px;
                padding: 1px;
            }

            &.active
            {
                cursor: none;
                pointer-events: none;
                opacity: 0.6;
            }
        }
        .flexBtn
        {     
            display: flex;
            button
            {
                font-size: 16px;
                background: #316879;
                color:#fff;
                border:none;
                border-radius:5px;
                padding: 5px 10px;
                margin: 0 5px;
                @include mq(phone-wide, max) {
                    font-size: 10px;
                    padding: 1px 2px;
                }
                &.active
                {
                    border:2px solid #fff;
                }
            }
        }
    }

    .indic
    {
        font-weight: bold;
        margin: 0 10px;
    }

    .jump
    {
        display: flex;
        align-items: center;
        margin: 0 10px ;

        input
        {
            max-width: 60px;
            padding: 5px;
            text-align: center;
            border: 1px solid #316879;
            border-radius: 5px;
            margin: 0 5px;
            color: #28525f;
        }
    }

}


.reducedPadding th,
.reducedPadding td{
    padding: 12px 6px;
    border-left: 1px solid $grey;
    border: 1px solid rgb(128, 128, 128, 0.3);
}

.header{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
    background-color: #000000;
    color: rgb(255, 255, 255);
}

.header-wrapper tr,
.header-wrapper td{
    border: none;
}
.row{
    font-size: 16px;
    color: #c4c4c4;
    font-weight: 500;
}

.table-adjusted{
    padding: 0px;
    border-radius: 2px;
    background-color: transparent;
    color: $green2;
}

// #316879, #f47a60, #7fe7dc, #ced7d8