.wrapper{
  overflow-y: scroll;
}

.link{
  font-size: 14px;
  text-decoration: underline;
  text-transform: lowercase;
  color: teal;

  &:hover{
    transform: scale(107%);
    filter: brightness(150%);
  }
}

.modal-body{
  max-height: 600px;
  min-height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;
}

.referrals{
  margin: 10px auto;
  cursor: pointer;

  &:hover{
    transform: scale(120%);
    color: rgb(28, 134, 99);
  }
}