@import "../../assets/scss/index.scss" ;

.backbutton{
  color: $green;
  font-size: 35px;
  font-weight: 900;
  cursor: pointer;
  &:hover{
      transform: scale(150%);
  }
}