@import '../../assets/scss/index.scss';

.fieldGroup
{
    display: grid;
    grid-template-columns:  35% 65%;
    margin: 10px auto;
    align-items: center;
    position: relative;
    padding-bottom: 12px;


    .error
    {
        position: absolute;
        left: 35%;
        bottom: 0;
        font-size: 10px;
        font-style: italic;
        color:$red;
    }

    .label
    {
        font-family: Raleway;
        font-style: italic;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: $black;
    }

    .text,.select
    {
        padding: 15px;
        background:#fff;
        border-radius: 16px;
        border:none;
        color: $black;

        &.disabled
        {
            background-color: #ccc;
            pointer-events:none;
            cursor:not-allowed;
        }
    }

    textarea
    {
        font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
}
