@import "../../../assets/scss/index.scss";

.head{
    @include flex-btw;
    margin-bottom: 0px;
    @include mq(phone-wide,max){
        @include flex-btw(column);
        h4{
            margin-bottom: 10px;
        }
    }
}

.search{
    @include searchBox;
}

.icon{
    color:$green;
    transition: all 0.07s ease-in;
    &:hover{
        transform: scale(1.4);
    }
}

.toggle{
    position: absolute;
    color: $green;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

.checks{
    @include flex-even;
    // input[type='checkbox']{
    //    @include checkbox(50%);
    // }
    .check{
        display: grid;
    }
}

.userForm{
    border-radius: 5px;
    padding: 0px 8%;

    @include mq(phone-wide, max){
        padding: 0px 0%;
      }

    .formRow{
        margin: 20px;
        padding: 5px 0px 5px 0px;
        
        @include mq(phone-wide,max){
            padding: 0px;
            flex-direction: column;
            align-items: flex-start;
            label{
                margin: 10px 0;
                align-self: flex-start;
            }    
        }
        .input_err_box{
            position: relative;
            display: grid;
            width: 100%;
        }
        input:not([type='checkbox']) {
            width: 100%;
            height: 46px;
            font-family: "Raleway", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
            color: #303030;
            padding: 15.5px 12px;
            font-size: 15px;
            border-radius: 4px;
            border: solid 1px rgba(53, 120, 229, 0.5);
            background-color: #fff;
            &:focus{
                border: 1px solid $green;
            }
        }
    }
    .error {
        position: relative;
        color: $red;
        padding: 10px 0;
        font-weight: bold;
        font-size: 12px;
      }
}

.btn{
    @include button(170px, 5px);
    @include mq(phone-wide, max){
      @include button(100px, 2px, 10px);
      margin-right: 10px ;
    }
    text-transform: uppercase;
    text-transform: uppercase;
    float: right;
    margin: 7px auto 7px auto;

}

.adduser{
    width: 100%;
    display: block;
}

.error{
    @include error;
}

label{
    text-transform: capitalize;
    margin-bottom: 4px;
}

.delete{
    color: orangered;
    &:hover{
        color: red;
    }
}


.backbutton{
    color: $green;
    font-size: 35px;
    font-weight: 900;
    cursor: pointer;
    &:hover{
        transform: scale(150%);
    }
} 

.createdAt{
    position: absolute;
    font-size: 13px;
    color:rgba(5, 16, 32, 0.5)
}

.edit{
    font-size: 7px;
    color: blueviolet;
}

.status{
    padding: 4px 0;
    font-size: 14px;
    border-radius: 12px;
    font-family: Arial, Helvetica, sans-serif;
}
.currency-card{
    display: flex;
    background-color: white;
    padding: 15px;
    margin: 10px auto;
    width: 400px;
    border-radius: 10px;

    .currency-image{
        width: 40px;
        margin-right: 20px;
        align-self: center;
    }

    .currency-name{
        flex: 30%;
        font-weight: 600;
        font-family: monospace;
        font-size: 18px;
        align-self: center;
    }

    .currency-amount{
        flex: 30%;
        font-weight: 300;
        font-family: monospace;
        font-size: 18px;
        align-self: center;
    }
}

.bold{
    font-weight: 500;
    margin-right: 20px;
}

.lifetime-value{
    border: 1px solid rgb(155, 142, 142, 0.2);
    border-radius: 5px;
    padding: 10px 80px;
    font-family: monospace;
}