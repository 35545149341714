@import "../../assets/scss/index.scss";

.header
{
    // background: #fff;
    background: #ffff;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 100;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);

    .name
    {
        text-transform: capitalize;
    }

    .dropDown{
        position: inherit;
        top: 100%;
        right: 0;
        padding: 7px 9px;
        width: 200px;
        background: #fff;
        border-top: 1px solid $grey2;
        border-bottom-left-radius: 10px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        @include mq (phone-small,max){
            width: 180px;
        }
        p,a{
            @include flex-btw;
            position: relative;
            margin: 5px 0;
            color: inherit;
            cursor: pointer;
            &:nth-child(2){
                margin-top: 8px;
                &::before{
                    bottom: -10px;
                }
            }
            &:hover{
                transform: scale(1.04);
            }
            span{
                text-transform: capitalize;
            }

            &::before{
                position: absolute;
                right: 50%;
                bottom: -4px;
                height: 1px;
                width: 0%;
                content: "";
                transform: scale(-1);
                background: $green;
                transition: all 300ms ease;
            }

            &:hover::before{
                right: 0;
                width: 100%;            
            }
        }
    }

    .icon{
        color:$green;
        cursor: pointer;
        font-size: 19px;
    }

    .drop{
        transform: rotate(0deg);
        &.open{
            transform: rotate(-180deg);
        }
    }

}

.error{
    font-family: Arial, Helvetica, sans-serif;
    color: red;
    font-size: 11px;
}